import $ from "jquery";
import "slick-carousel";
export default {
  init() {
    // JavaScript to be fired on all pages
    $(document).ready(function() {
      $(".main-slider").slick({
        fade: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000
      });

      $(".testimonial-slider")
        .slick({
          fade: false,
          dots: true,
          autoplay: false,
          autoplaySpeed: 3000,
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
          rows: 0,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        })
        .on("setPosition", function(event, slick) {
          slick.$slides.css("height", slick.$slideTrack.height() + "px");
        });

      $(".dropdown-toggle").on("mouseenter", function() {
        // make sure it is not shown:
        if (
          !$(this)
            .parent()
            .hasClass("show")
        ) {
          $(this).click();
        }
      });

      function windowScrollCheck() {
        var nav = $(".main-navbar");
        var top = 200;
        if ($(window).scrollTop() >= top) {
          nav.addClass("scrolled");
        } else {
          nav.removeClass("scrolled");
        }
      }
      $(window).scroll(function() {
        windowScrollCheck();
      });
      windowScrollCheck();
    });

    $(".scroller").on("click", function(e) {
      // prevent default anchor click behavior
      e.preventDefault();

      // store hash
      var hash = "#" + $(this).data("target");

      // animate
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top
        },
        300,
        function() {
          // when done, add hash to url
          // (default click behaviour)
          // window.location.hash = hash;
        }
      );
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
